import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './auth/services/authguard.service';

const routes: Routes = [
	{
		path: 'auth',
		loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
	},
	{
		path: 'particulier',
		loadChildren: () => import('./particulier/particulier.module').then(m => m.ParticulierModule)
	},
	{
		path: '',
		canActivate : [AuthGuardService],
		loadChildren: () => import('./interface/interface.module').then(m => m.InterfaceModule),
	},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
