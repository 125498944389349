import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { NbAuthService } from '@nebular/auth';
import { NbRoleProvider } from '@nebular/security';


@Injectable()
export class RoleProvider implements NbRoleProvider {

	constructor(private authService: NbAuthService) {
	}

  	getRole(): Observable<string[]> {
		return this.authService.onTokenChange()
		.pipe(
			map((token) => {
				let roles = ['operateur_terrain'];
				if(token.isValid()) {
					roles = [];
					for(let role of token.getPayload()['roles']) {
						roles.push(role.key);
					}
				}
				// console.log(roles);
				return roles;
			}),
		);
  	}
}