export const roleDefinitions = {
	operateur_terrain: {
	 	view: ['dashboard', 'logistique', 'machines', 'chantierclient', 'depots', 'besoin_field', 'facturation_field'],
		edit: ['depot', 'level2depot', 'besoin_field_temps'],
		validT: ['intervention']
	},
	operateur_terrain_plus: {
		parent: 'operateur_terrain',
		view: ['all_depots'],
		edit: ['machine_code', 'machine', 'level2machine', 'level1depot', 'depot_code', 'besoin_field'],
	},
	metreur: {
		parent: 'operateur_terrain_plus',
		view: ['allchantiercolumn', 'chantiers', 'gogeta', 'gazo', 'allmachinescolumn', 'mapmachine'],
		edit: ['chantierstatus', 'chantierplanning'],
		validM: ['intervention']
	},
	assistante: {
		parent: 'metreur',
		edit: ['facturation_field', 'allstates'],
		delete: ['intervention'],
		validA: ['intervention']
	},
	admin: {
		parent: 'assistante',
		view: ['admin', 'modeles'],
		edit: ['level1machine'],
		add: ['machine', 'depot', 'modele'],
		delete: ['machine', 'depot']
	}
}