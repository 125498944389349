import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { tap, catchError } from 'rxjs/operators';
import { NbToastrService, NbComponentStatus  } from '@nebular/theme';

import { NbAuthService } from '@nebular/auth';

import { Router } from '@angular/router';
import { environment } from './../../environments/environment';


@Injectable({ providedIn: 'root' })
export class HttpPostInterceptor implements HttpInterceptor {

	private requests: HttpRequest<any>[] = [];
	// constructor() {}

	// intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
	// 	console.log('request set');
	// 	return next.handle(httpRequest);
	// }
		
	constructor(
		private toastrService: NbToastrService,
		private router:Router,
		private authService: NbAuthService
	) {}

	removeRequest(req: HttpRequest<any>) {
		const i = this.requests.indexOf(req);
		if (i >= 0) {
			this.requests.splice(i, 1);
		}
 	}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> { 

		console.log(environment);
		// console.log(request);
		let finalUrl = environment.baseUrl+request.url;
		if(request.responseType == 'blob') {
			finalUrl = environment.baseImageUrl+request.url;
		}
		request = request.clone({
			url: finalUrl,
			headers: request.headers
			.set('Content-Type', 'application/json')
			.set('ngsw-bypass', 'true')
			.set('authorization', environment.authorization)
		});

		// console.log();

		return this.authService.getToken()
		.pipe(
		  	switchMap((token) => {
				if (token && token.getValue()) {
					request = request.clone({
						headers: request.headers.set('authentication', token.getValue())
					});
				}
				else {
					if(localStorage.getItem('privateKey')) {
						request = request.clone({
							headers: request.headers.set('authentication', localStorage.getItem('privateKey')?.toString() || '')
						});
					}
				}
				return next.handle(request).pipe(
					tap(evt => {
						// console.log(evt);
						if (evt instanceof HttpResponse) {
							this.removeRequest(request);
							if(evt.body ){
								console.log(evt);
								// if(evt.body.success){
								// 	var status: NbComponentStatus = 'success';
								// 	this.toastrService.success(evt.body.success, 'Succès', { status } );
								// }else if(evt.body.error){
								// 	if(request.body.object != 'action' || request.body.method != "getList"){
								// 		var status: NbComponentStatus = 'warning';
								// 		this.toastrService.show(evt.body.error, 'Attention', { status } );
								// 	}
									
								// }
								if(evt.body.code){
								    if(evt.body.code == 'ok'){
									    var status: NbComponentStatus = 'success';
									    this.toastrService.success(evt.body.message, 'Succès', { status } );
								    }else if(evt.body.code == 'error'){
									    if(request.body.object != 'action' || request.body.method != "getList"){
										    var status: NbComponentStatus = 'warning';
										    this.toastrService.show(evt.body.message, 'Attention', { status } );
									    }	
								    }
								}
							}
						}
					}),
					catchError((err: any) => {
						
						if(err instanceof HttpErrorResponse) {				
							var status: NbComponentStatus = 'danger';
							this.removeRequest(request);
							if(err.error){
								if(err.status == 401) {
									this.toastrService.show(err.error.message, 'Erreur '+err.status, { status } );
									setTimeout(() => {
										this.router.navigate(['/auth/login'], {});	
									}, 2000);
								}
								else if(err.status == 409) {
									throw err;
								}
								else {
									if(err.error.message){
										this.toastrService.show(err.error.message, 'Erreur '+err.status, { status } );
									}else if(err.error.version){
										this.toastrService.show(err.error.version, 'Erreur '+err.status, { status } );
									}else{
										this.toastrService.show(err.error, 'Erreur '+err.status, { status } );
									}	
								}
							}else{
								this.toastrService.show("Aucune réponse du webservice", 'Erreur '+err.status, { status } );
								if(err.status == 403){
									// token invalide
									this.router.navigate(['/auth/login'], {});	
								}
							}
							
						}
						return of(err);
					})
		 	 	)
			})
		);
    }
}

  