import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserService {

	constructor(
		private http: HttpClient,
		//private variablesService: VariablesService
	) {}

	sendLogin(login: string, password: string) {
		var args = {
            'login': login,
			'password': password
		};
		
		return this.http.post(
			'auth',  
			args 
		);
	}

	getInfo() {
		return this.http.get('user');
	}
}
