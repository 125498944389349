import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NbThemeModule, NbLayoutModule, NbToastrModule } from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbPasswordAuthStrategy, NbAuthModule, NbAuthJWTToken } from '@nebular/auth';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpPostInterceptor } from './services/httppost.interceptor';
import { AuthGuardService } from './auth/services/authguard.service';
import { NbSecurityModule, NbRoleProvider } from '@nebular/security';
import { RoleProvider } from './services/role.provider';
import { roleDefinitions } from './services/role.definitions';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { LightboxModule } from 'ngx-lightbox';
registerLocaleData(localeFr);

import { DateFnsConfigurationService } from 'ngx-date-fns';
import { fr } from "date-fns/locale";
const frenchConfig = new DateFnsConfigurationService();
frenchConfig.setLocale(fr);
import { NbSidebarModule, NbMenuModule, NbDatepickerModule, NbDialogModule,  } from '@nebular/theme';

import { NbDateFnsDateModule } from '@nebular/date-fns';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		BrowserModule,
		GooglePlaceModule,
		HttpClientModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		NbThemeModule.forRoot({ name: 'corporate' }),
		NbLayoutModule,
		NbEvaIconsModule,
		LightboxModule,
		NbSecurityModule.forRoot({
			accessControl: roleDefinitions
		}),
		NbToastrModule.forRoot(),
		NbAuthModule.forRoot({
			strategies: [
				NbPasswordAuthStrategy.setup({
					name: 'email',
					baseEndpoint: '',
					login: {
						endpoint: 'auth',
					},
					logout:{
						endpoint: '',
						redirect: {
							success: '/auth/login'
						}
					},
					token: {
						class: NbAuthJWTToken,
						key: 'accessToken'
					}
				}),
			],
			forms: {},
		}),
		NbSidebarModule.forRoot(),
		NbMenuModule.forRoot(),
		NbDatepickerModule.forRoot(),
		NbDateFnsDateModule.forRoot({ format: 'dd/MM/yyyy' }),
		NbDialogModule.forRoot(),
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: environment.production,
			// Register the ServiceWorker as soon as the application is stable
			// or after 30 seconds (whichever comes first).
			registrationStrategy: 'registerWhenStable:30000'
		}),
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpPostInterceptor,
			multi: true
		},
		{ provide: NbRoleProvider, useClass: RoleProvider },
		{ provide: LOCALE_ID, useValue: "fr-FR" }, //your locale
		{ provide: DateFnsConfigurationService, useValue: frenchConfig },
		AuthGuardService,
		{ provide: LOCALE_ID, useValue: 'fr-FR'},
	],
	bootstrap: [AppComponent]
})
export class AppModule { 

}
