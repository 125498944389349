export const environment = {
  	production: false,
	baseUrl: 'https://assecheur-api.webapic.com/api/',
	baseImageUrl: 'https://assecheur-api.webapic.com/',
	authorization: 'Basic YXNzZWNoZXVyYXBpOmFzc2VjaGV1cmFwaQ==',
	prefix: {
		'DE': 'DES-',
		'BR': 'BRA-',
		'TE': 'HUM-',
		'PI': 'PIE-',
		'VE': 'CAM-',
		'BO': 'BOX-',
		'LP': 'DIV-',
		'OT': 'OTH-'
	}
};
