import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot,RouterStateSnapshot } from '@angular/router';
 
import { UserService } from './user.service'; 
import { NbAuthService } from '@nebular/auth';
import { tap } from 'rxjs/operators';

@Injectable()
export class AuthGuardService implements CanActivate {
 
    constructor(
		private router:Router,
		private userService: UserService,
		private authService: NbAuthService
	) {
    }
 
    async canActivate(): Promise<boolean> {
		if (!await this.authService.isAuthenticated().toPromise()) {
			this.router.navigate(['/auth/login']);
			return false; 
        } 
		
	  	return true;
    }
 
}