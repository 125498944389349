import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
	selector: 'app-root',
	template: `<router-outlet></router-outlet>`
})
export class AppComponent {
  title = 'assecheur';
  promptEvent:any;

  constructor(
	private http: HttpClient,
	private router: Router
	) {
		window.addEventListener('beforeinstallprompt', event => {
			this.promptEvent = event;
		});
	}

	async ngOnInit(): Promise<void> {
		if(localStorage.getItem('privateKey')) {
			this.router.navigate(['/particulier/home'], {});
		}
	}

	async sendCalltest() {
		console.log('sendCalltest');
		await this.http.post(
			'auth',  
			{} 
		).toPromise();
	}
 }
